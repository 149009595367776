/* ========================================================================
 * DOM-based Routing
 * Based on //goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        window.viewportUnitsBuggyfill.init();
        if (document.getElementById('patched-viewport')) {
          window.addEventListener('resize', viewportUnitsBuggyfill.refresh, true);
        }

        var offset;
        offset = 100;

        $('a[href*="#"]:not([href="#"],[data-slide="prev"],[data-slide="next"],[data-toggle="collapse"],[data-toggle="tab"],[href="#tab"])').click(function() {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = (target.length ? target : $('[name=' + this.hash.slice(1) + ']'));
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - offset
              }, 1000);
              $('.navbar-collapse.in').removeClass("in");
              return false;
            }
          }
        });

        /* copy loaded carousel images into thumbnails */
        $('.show-thumbnails > .carousel').each(function(i) {

          var carouselId = $(this).attr('id');

          var containerId = 'cthumbs-' + Math.floor((Math.random() * 100) + 1);
          var thumbnailContainer = $('<div class="carousel-thumbnails" id="cthumbs-' +
            containerId + '"></div>');
          thumbnailContainer.appendTo($(this).parent());
          var dataIndex = 0;
          $('.carousel .show-thumbnails .carousel-inner div.item').each(function(i) {

            var item = $('<a data-gallery="gallery" data-target="#' + carouselId + '" data-slide-to="' +
              dataIndex + '"></a>');
            var innerItem = $('<div class="thumbnail"></div>');
            var style = $(this).attr('style');
            innerItem.attr("style", style);
            innerItem.appendTo(item);
            item.appendTo(thumbnailContainer);
            dataIndex += 1;
          });
        });

        /* when clicking a thumbnail */
        $('.carousel-thumbnails a').click(function(e) {
          var carouselId = $(this).data('target');
          var idx = $(this).data("slide-to");
          var id = parseInt(idx);
          $(this).parent().siblings(".carousel.slide").carousel(carouselId); // slide carousel to selected
        });

        $('.events-tabs .nav-tabs a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
          $('#calendar').fullCalendar({
            firstDay: 1,
            header: {
              left: 'title',
              center: '',
              right: 'today prev,next'
            },
            // put your options and callbacks here
            events: {
              url: '/wp-json/events/v1/list/',
              type: 'GET',
              error: function() {
                console.log('there was an error while fetching events!');
              }
            },
            eventTextColor: '#FFF'
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*
         *  new_map
         *
         *  This function will render a Google Map onto the selected jQuery element
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param $el (jQuery element)
         *  @return  n/a
         */

        function new_map($el) {
          // var
          var $markers = $el.find('.marker');
          // vars
          var $zoom = parseInt($el.attr('data-zoom'));
          var args = {
            zoom: $zoom,
            center: new google.maps.LatLng(0, 0),
            scrollwheel: false,
            styles: [{
                "elementType": "geometry",
                "stylers": [{
                  "color": "#ebe3cd"
                }]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#523735"
                }]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [{
                  "color": "#f5f1e6"
                }]
              },
              {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#c9b2a6"
                }]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#dcd2be"
                }]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#ae9e90"
                }]
              },
              {
                "featureType": "landscape.natural",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#dfd2ae"
                }]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#dfd2ae"
                }]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#93817c"
                }]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [{
                  "color": "#a5b076"
                }]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#447530"
                }]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#f5f1e6"
                }]
              },
              {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#fdfcf8"
                }]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#f8c967"
                }]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#e9bc62"
                }]
              },
              {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#e98d58"
                }]
              },
              {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#db8555"
                }]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#806b63"
                }]
              },
              {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#dfd2ae"
                }]
              },
              {
                "featureType": "transit.line",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#8f7d77"
                }]
              },
              {
                "featureType": "transit.line",
                "elementType": "labels.text.stroke",
                "stylers": [{
                  "color": "#ebe3cd"
                }]
              },
              {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#dfd2ae"
                }]
              },
              {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [{
                  "color": "#b9d3c2"
                }]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "color": "#92998d"
                }]
              }
            ]
          };
          // create map
          var map = new google.maps.Map($el[0], args);
          // add a markers reference
          map.markers = [];
          // add markers
          $markers.each(function() {
            add_marker($(this), map);
          });
          // center map
          center_map(map, $zoom);
          // return
          return map;
        }

        /*
         *  add_marker
         *
         *  This function will add a marker to the selected Google Map
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param $marker (jQuery element)
         *  @param map (Google Map object)
         *  @return  n/a
         */

        function add_marker($marker, map) {
          // var
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map
          });

          marker.setVisible(Boolean($marker.attr('data-show-marker')));
          // add to array
          map.markers.push(marker);
          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            });
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open(map, marker);
            });
          }
        }

        /*
         *  center_map
         *
         *  This function will center the map, showing all markers attached to this map
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param map (Google Map object)
         *  @return  n/a
         */

        function center_map(map, zoom) {
          // vars
          var bounds = new google.maps.LatLngBounds();
          // loop through all markers and create bounds
          $.each(map.markers, function(i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
          });

          // only 1 marker?
          if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(zoom);
          } else {
            // fit to bounds
            map.fitBounds(bounds);
          }
        }

        /*
         *  document ready
         *
         *  This function will render each map when the document is ready (page has loaded)
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 5.0.0
         *
         *  @param n/a
         *  @return  n/a
         */
        // global var
        var map = null;
        $(document).ready(function() {
          $('.flex-map').each(function() {
            // create map
            map = new_map($(this));
          });
        });

        function postPaging() {
          var total_records = parseInt($("#post-list").data("records"));
          var shown_records = parseInt($("#post-list article").length);
          if (total_records > shown_records) {
            console.log(total_records + '>' + shown_records);
            $('#pagination').show();
          } else {
            console.log(total_records + '<' + shown_records);
            $('#pagination').hide();
          }
        }
        postPaging();

        $('body').on('click', '#pagination a', function(e) {
          e.preventDefault();
          var total_records = $("#post-list").data("records");
          var shown_records = $("#post-list article").length;
          var records_per_page = $("#post-list").data("records-page");
          var new_link = (shown_records / records_per_page) + 1;
          var link = $(this).attr('href').slice(0, -1);

          var paths = link.split('/');
          paths[paths.length - 1] = new_link; // new value
          link = paths.join('/');

          $.get(link, function(data) {
            //$( data ).find( "#post-list article" ).appendTo( "#post-list" );
            var newArticles = $(data).find("#post-list article");
            newArticles.insertBefore("#pagination");

          }).done(function(data) { postPaging(); });

        });

        function keywordSearch() {
          var searchText = $('#post-filter').val().toLowerCase(),
            searchStringLength = $('#post-filter').val().length;
          if (searchStringLength > 2) {
            $('.post-item').each(function() {
              var keywordContent = $(this),
                text = $(this).text(),
                textL = text.toLowerCase();

              if (textL.indexOf(searchText) !== -1) {
                keywordContent.addClass("result").show();
              } else {
                keywordContent.removeClass("result").hide();
              }
            });
          } else {
            $(".post-item").addClass("result").show();
          }
          $('#post-count').text($('.post-item.result').length);
        }

        var totalRecords = 0;
        $('.post-list').each(function(index) {
          totalRecords = totalRecords + $(this).data("records-total");
        });
        $('#post-count').text(totalRecords);

        function addShowAll() {
          if (!$('#show-all').length) {
            $('.list-unstyled').append('<li id="show-all"><a class="search-category" data-category="" href="#">Show All</a></li>');
          }
        }

        function removeShowAll() {
          if ($('#show-all').length) {
            $('.list-unstyled').children('#show-all').remove();
          }
        }

        function getUrlParameter(sParam) {
          var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

          for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : sParameterName[1];
            }
          }
        }

        var timer = 0;

        $('#post-filter').on('keyup', function() {
          if (timer) {
            clearTimeout(timer);
          }
          if ($('#post-filter').val() === "") {
            removeShowAll();
          } else {
            addShowAll();
          }
          timer = setTimeout(keywordSearch, 400);
        });

        $(document).on('click', '.search-category', function() {
          $('#post-filter').val($(this).data("category"));
          keywordSearch();
          if ($('#post-filter').val() === "") {
            removeShowAll();
          } else {
            addShowAll();
          }
        });


        if (getUrlParameter("category")) {
          $('#post-filter').val(getUrlParameter("category"));
          keywordSearch();
          addShowAll();
        } else {
          $('#post-filter').val("");
        }

        // Hide read more button on single project page after clicking.
        $('#read-more-button').on('click', function() {
          $(this).addClass('hidden');
        });

        if ($(".panzoom").length) {
          (function() {
            $(".panzoom").wrap("<div class='panzoom-focal'></div>");
            $(".panzoom-focal").append("<div class='panzoom-reset btn btn-primary'>Reset Zoom</div>");
            var $section = $('.floorplan-parent');
            var $panzoom = $section.find('.panzoom').panzoom({
              increment: 0.5,
              minScale: 1,
              maxScale: 8,
              startTransform: 'scale(4.0)',
              $zoomIn: $parent.find('A[href=#zoom_in]'),
              $zoomOut: $parent.find('A[href=#zoom_out]'),
              contain: 'invert'
            }).panzoom('zoom', true);
            // handle scrolling in and out
            $panzoom.parent().on('mousewheel.focal', function(e) {
              event.preventDefault();
              var delta = e.delta || e.originalEvent.wheelDelta;
              var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
              $panzoom.panzoom('zoom', zoomOut, {
                increment: 0.1,
                animate: false,
                focal: e
              });
            });
          })();
        }
        /* $(function() {
           var svgPanZoom = $("svg").svgPanZoom({
               $mouseWheel: true,
             });
         });
         */
        $(".fancybox").fancybox({
          helpers: {
              thumbs: {
                  width  : 40,
                  height : 40,
                  source  : function(current) {
                      return $(current.element).data('thumbnail');
                  }
              },
              buttons: {}
          }
        });

        $(function() {
          $(".floorplan").panzoom({
            $reset: $('#zoom-reset'),
            $zoomIn: $('#zoom-in'),
            $zoomOut: $('#zoom-out'),
            $zoomRange: $("input[type='range']"),
          });
        });
        $("a.grave").on("click touchend", function() {
          event.preventDefault();
          window.location.href = $(this).attr('href');
        });
        $('a.grave').onclick = function(event) {
          event.preventDefault();
        };
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        (function() {
          $('#posts-carousel .item').each(function() {
            var itemToClone = $(this);

            for (var i = 1; i < 3; i++) {
              itemToClone = itemToClone.next();

              // wrap around if at end of item collection
              if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
              }

              // grab item, clone, add marker class, add to collection
              itemToClone.children(':first-child').clone()
                .addClass("cloneditem-" + (i))
                .appendTo($(this));
            }
          });
        }());

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        if ($('#modal-popup').hasClass('trigger')) {
          $('#modal-popup').modal('show');
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
